import { Bvh, Center, Html, Text3D, useGLTF } from "@react-three/drei";
import { Suspense, useContext, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import axios from "axios";
import { useFrame } from "@react-three/fiber";
import { DataContext } from "./DataContext";

export default function Buoy() {
  let colourHover = null;
  const buoyRef = useRef();
  const [modelPath, setModelPath] = useState("./models/lowPolyCube.glb"); // default path
  const buoy = useGLTF(modelPath);
  const mainMaterial = new THREE.MeshBasicMaterial({ color: 0x203c6e });
  let modelDetail;
  const [depth, setDepth] = useState(null);
  const [name, setName] = useState(null);
  const [sidebarTitle, setSidebarTitle] = useState(null);
  const [sidebar, setSidebar] = useState(null);
  const [firstCheck, setFirstCheck] = useState(null);
  const [highlightDrawings, sethighlightDrawings] = useState(null);
  const [highlightImages, sethighlightImages] = useState(null);
  const [mixer, setMixer] = useState(null);
  const { modelDetails, setModelDetails } = useContext(DataContext);
  const [user, setUser] = useState(null); 

  const [ctrlDown, setCtrlDown] = useState(false);
  const [lineId, setLineId] = useState(0);
  const [line, setLine] = useState(null);
  const [drawingLine, setDrawingLine] = useState(false);
  const [measurementLabels, setMeasurementLabels] = useState({});

  useEffect(() => {
    setSidebarTitle(document.querySelector(".highlightName"));
    setFirstCheck(document.querySelector(".firstChecker"));
    sethighlightDrawings(document.querySelector(".highlightDrawings"));
    sethighlightImages(document.querySelector(".highlightImages"));
    setSidebar(document.getElementById("sidebar"));
  }, []);

  useEffect(() => {
    if (buoy.animations && buoy.animations.length > 0) {
      const newMixer = new THREE.AnimationMixer(buoy.scene);
      const action = newMixer.clipAction(buoy.animations[0]);
      action.play();
      setMixer(newMixer);
    }
  }, [buoy]);

  useFrame((state, delta) => {
    if (mixer) {
      mixer.update(delta);
    }
  });

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const user = storedUser ? JSON.parse(storedUser) : null;
    setUser(user);

    if (user && user.token) {
      axios
        .get("https://seasite.co.uk/api/users/user-info", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(function (response) {
          const modelDetail = response.data;
          setName(modelDetail.name);
          setDepth(modelDetail.Depth);
          setModelPath(modelDetail.Model);
          setModelDetails(modelDetail);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            axios
              .post(
                "https://seasite.co.uk/api/users/refresh-token",
                {},
                {
                  headers: {
                    Authorization: `Bearer ${user.refreshToken}`,
                  },
                }
              )
              .then((res) => {
                console.log("Received new token:", res.data.token);
                console.log(res.status);
                if (res.status === 200) {
                  const updatedUser = { ...user, token: res.data.token };
                  localStorage.setItem("user", JSON.stringify(updatedUser));
                  setUser(updatedUser); // Update the user state
                  console.log("Making new request with refreshed token");
                  axios
                    .get("https://seasite.co.uk/api/users/user-info", {
                      headers: {
                        Authorization: `Bearer ${updatedUser.token}`,
                      },
                    })
                    .then(function (response) {
                      const modelDetail = response.data;
                      console.log(modelDetail);
                      setName(modelDetail.name);
                      setDepth(modelDetail.Depth);
                      setModelPath(modelDetail.Model);
                      setModelDetails(modelDetail);
                    });
                }
              });
          }
        });
    }
  }, []);

  return (
    <>
      <Bvh>
        <Suspense>
          <group>
            <primitive
              ref={buoyRef}
              object={buoy.scene}
              position={modelDetails.Position}
              onClick={(event) => {
                event.stopPropagation();
                if (sidebarTitle) {
                  sidebarTitle.innerHTML = event.object.userData.name;
                  sidebar.style.animation = "none";

                  setTimeout(() => {
                    sidebar.style.animation = "fadeInSidebar 1s ease-in both";
                  }, 10);

                  sidebarTitle.classList.add("wipe-effect");
                  setTimeout(
                    () => sidebarTitle.classList.remove("wipe-effect"),
                    1500
                  );

                  if (event.object.userData.info) {
                    setFirstCheck(document.querySelector(".firstChecker"));
                    firstCheck.innerHTML = event.object.userData.info;

                    firstCheck.classList.add("wipe-effect");
                    setTimeout(
                      () => firstCheck.classList.remove("wipe-effect"),
                      1500
                    );
                  } else {
                    firstCheck.innerHTML = "";
                  }
                }
                if (event.object.userData.drawing) {
                  sethighlightDrawings(
                    document.querySelector(".highlightDrawings")
                  );
                  highlightDrawings.innerHTML = event.object.userData.drawing;
                } else {
                  sethighlightDrawings(
                    document.querySelector(".highlightDrawings")
                  );
                  highlightDrawings.innerHTML = "";
                }
                if (event.object.userData.image) {
                  sethighlightImages(
                    document.querySelector(".highlightImages")
                  );
                  highlightImages.innerHTML = event.object.userData.image;
                } else {
                  sethighlightDrawings(
                    document.querySelector(".highlightImages")
                  );
                  highlightImages.innerHTML = "";
                }
              }}
              onPointerMissed={(e) => {
                e.stopPropagation();
                if (sidebarTitle && firstCheck) {
                  sidebarTitle.innerHTML = name;
                  firstCheck.innerHTML = "";
                  highlightDrawings.innerHTML = "";
                  highlightImages.innerHTML = "";
                }
              }}
              onPointerEnter={(e) => {
                e.stopPropagation();
                colourHover = e.object.material.color.clone();
                const material = e.object.material.clone();
                material.color.set(0xff0000);
                e.object.material = material;
              }}
              onPointerLeave={(e) => {
                e.stopPropagation();
                e.object.material.color.copy(colourHover);
              }}
            />
          </group>
        </Suspense>
      </Bvh>
      <Suspense>
        <Center position={[-5, modelDetails.Depth / 4 + 4, 0]}>
          <Text3D
            position={[0, 5, 0]}
            rotation={[0, 0.375, 0]}
            material={mainMaterial}
            font={"./fonts/helvetiker_regular.typeface.json"}
            size={0.7}
            height={0.2}
            curveSegments={12}
            bevelEnabled
            bevelThickness={0.02}
            bevelSize={0.02}
            bevelOffset={0}
            bevelSegments={5}
          >
            {name + `\nWater Depth: ` + depth + `m`}
          </Text3D>
        </Center>
      </Suspense>
    </>
  );
}
