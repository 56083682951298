import { VictoryChart, VictoryLine, VictoryAxis } from "victory";
import AnalyticsCharting from "./AnalyticsCharting";

export default function Graphs() {
  const data = [
    { x: 1, y: -271 },
    { x: 2, y: -274.41 },
    { x: 3, y: -272.46 },
    { x: 4, y: -265.63 },
    { x: 5, y: -274.41 },
    { x: 6, y: -271 },
    { x: 7, y: -265.63 },
    { x: 8, y: -274.9 },
    { x: 9, y: -271.97 },
    { x: 10, y: -272.95 },
    { x: 11, y: -277.34 },
    { x: 12, y: -265.63 },
    // ...
  ];

  return (
    <>
      <div className="graphContainer">
        <div id="displacementMap">
          <h3>Displacement Map</h3>
          <img src="images/Target.png" alt="Sealand Projects" />
        </div>
        <div id="imuData">
          <h3>IMU Data</h3>
          <AnalyticsCharting />
        </div>
      </div>
      <div className="dataDate">
        <div className="startDateContainer">
          Start Date
          <input type="date" id="startDate" name="startDate" />
        </div>
        <div className="endDateContainer">
          End Date
          <input type="date" id="endDate" name="endDate" />
        </div>
      </div>
      <div className="refreshContainer">
        <button >Refresh</button>
      </div>
    </>
    // <VictoryChart domain={{ y: [-Math.max(...data.map(point => point.y)), -Math.min(...data.map(point => point.y))] }}>
    //   <VictoryLine data={data.map(point => ({ ...point, y: -point.y }))}style={{
    //   data: { stroke: "#c43a31" },
    //   parent: { border: "1px solid #ccc"}
    // }}/>
    //   <VictoryAxis label="Minutes"/>
    //   <VictoryAxis dependentAxis label=" Accelerometer(milli g)" fixLabelOverlap />
    // </VictoryChart>
  );
}
