// index.jsx

import "./style.css";
import ReactDOM from "react-dom/client";
import { Canvas } from "@react-three/fiber";
import Experience from "./Experience.jsx";
import { useState } from "react";
// import LoginBox from "./LoginBox";
import Camera from "./Camera";
import { PerspectiveCamera } from "@react-three/drei";
import Chart from "./interface/Chart";
import LineChart from "./interface/Chart";
import Interface from "./Interface";
import {BrowserRouter as Router} from 'react-router-dom'
import App from "./App";


const root = ReactDOM.createRoot(document.querySelector("#root"));
// const [isLoggedIn, useIsLoggedIn] = useState(false)
root.render(
  <>
    <div className="container">
      {/* <LineChart/> */}
      
      <App/>
      
      

      
    </div>
  </>
);
