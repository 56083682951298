import * as THREE from "three";
import TopWater from "./TopWater";
import { Center, Text3D, useTexture } from "@react-three/drei";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import Seabed from "./World/Seabed";
import { Suspense, useContext, useEffect, useRef } from "react";
import Rain from "./World/Rain";
import Clouds from "./World/Cloud";
import Gull from "./World/Gull";
import { DataContext } from "./DataContext";
import { useCameraStore } from "./Store";
import Fish from "./World/Fish";



export default function World({ isLoggedIn }) { 
  const [colorMap, normalMap] = useTexture(['./textures/dirt/color.jpg', './textures/dirt/normal.jpg'])
  const mainMaterial = new THREE.MeshBasicMaterial({color:0x203C6E});   
  const { modelDetails } = useContext(DataContext);

  const { camera } = useThree();
  const { updatePosition } = useCameraStore()

useFrame(() => {
  updatePosition(camera.position)  
})
  const oceanShaderMaterial = {
  
    uniforms: {
        opacity: { value: 0.95  },
        depth:{value:modelDetails.Depth}
      },
    vertexShader: `
    varying vec2 vUv;
    void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vUv = uv;
    }
    `,
    fragmentShader: `
    varying vec2 vUv;
    uniform float opacity;
    uniform float depth;
    

    void main() {
        float strength = (abs(cameraPosition.y))/    depth;
        vec3 test = vec3(0.02,0.196,0.2);
        vec3 test2 = vec3(0.008,0.098,0.098);
        //float opacity = mix(0.9, 1.0, strength);
        vec3 mixedColor = mix(test,test2, strength);
        test = vec4(mixedColor, opacity);
        gl_FragColor = test;
    }
    `,
  };
  const testShaderMaterial = {
    uniforms: {
      opacity: { value: 0.95 },
      depth: { value: modelDetails.Depth }
    },
    vertexShader: `
      varying vec2 vUv;
  
      void main()
      {
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          vUv = uv;
      }
      `,
    fragmentShader: `
      varying vec2 vUv;
      

      uniform float depth;
      // Smoothstep function with a custom range
      float smoothstep_custom(float edge0, float edge1, float x) {
          float t = clamp((x - edge0) / (edge1 - edge0), 0.0, 1.0);
          return t * t * (3.0 - 2.0 * t);
      }
  
      void main()
      {
        float strength =  clamp(abs(cameraPosition.y) / depth, 0.0, 1.0);
        vec3 test = vec3(0.02,0.196,0.2);
        vec3 test2 = vec3(0.008,0.098,0.098);
        float opacity1 = mix(0.9, 1.0, strength);
        vec3 mixedColor = mix(test,test2, strength);
          
          
          float posX = mix(0.0,1.0,(cameraPosition.x+1250.0)/2500.0);
          float posZ = mix(0.0,1.0,(-cameraPosition.z+1250.0)/2500.0);
          // Define the position of the center of the circle
          vec2 circleCenter = vec2(posX, posZ);  // Adjust the center position as needed
  
          // Calculate distance from the custom center and scale it
          float distanceToCenter = distance(vUv, circleCenter);
          float scaledDistance = distanceToCenter * (3.0 + ((abs(cameraPosition.y))/10.0)); // Adjust the scaling factor as needed
  
          // Define the color you want to use
          vec3 targetColor = vec3(0.0, 0.102, 0.2);
  
          // Calculate opacity based on a smaller circular gradient using custom smoothstep
          float opacity = smoothstep_custom(0.0, 0.3, scaledDistance);  // Adjust the range as needed
  
          // Apply the calculated opacity to the color
          vec3 finalColor = mixedColor * opacity;
  
          // Output the final color with the calculated opacity
          gl_FragColor = vec4(finalColor, opacity);
      }
      `,
  };
  
  const gullPositions = [
    [0, 0, 0],
    [2, 1, 2],
    [3, 2, 3],
    // Add more positions as needed
  ];
  const fishPositions = [
    [0, -10, 10],
    [5, -12, 12],
    [5, -11, 8],
    // Add more positions as needed
  ];
  const gullRef = useRef()
  const fishRef = useRef()
  
  useFrame((state) => {
    if(gullRef.current.position.x>50){
      gullRef.current.position.x = -50
    }
    if(fishRef.current.position.x>camera.position.x+35){
      fishRef.current.position.x = camera.position.x-50
    }
    const t = state.clock.getElapsedTime();
    gullRef.current.position.x += 0.025; // move forward slowly
    fishRef.current.position.x += 0.025; // move forward slowly
    gullRef.current.position.y = 10 + Math.sin(t)/2; // bob up and down
  });
  
  return (
    <>
        <Seabed/>
      {/* Ocean Water */}
      {/* <mesh receiveShadow position-y={-20.05} side={THREE.BackSide} >

        <boxGeometry args={[2500, 40, 2500]} />
        <shaderMaterial attach="material" args={[oceanShaderMaterial]} transparent side={THREE.BackSide} />
      </mesh> */}
      {/* <mesh position={[0,-1.1,0]}rotation-x={-Math.PI/2}>
        <planeGeometry args={[2500,2500]}/>
        <shaderMaterial attach="material" args={[oceanShaderMaterial]} transparent side={THREE.BackSide} />
      </mesh> */}
      <mesh position={[0,-0.5,0]}rotation-x={-Math.PI/2} frustumCulled={false}>
        <planeGeometry args={[2500,2500]}/>
        <shaderMaterial needsUpdate attach="material" args={[testShaderMaterial]} transparent side={THREE.BackSide} />
        
      </mesh>
      {isLoggedIn&&<mesh  position={[0,(-(modelDetails.Depth/2))-1.1  ,0]}>
        <boxGeometry args={[2500,modelDetails.Depth+1,2500]} frustumCulled={false} />
        <meshBasicMaterial attach="material-0" color="#053233"side={THREE.BackSide} />
      <meshBasicMaterial attach="material-1" color="#053233" side={THREE.BackSide} />
      <meshBasicMaterial attach="material-2" color="#053233" transparent opacity={0.75 + Math.abs(camera.position.y * 0.001) } side={THREE.BackSide}/>
    
      <meshBasicMaterial attach="material-3" color="#FFFF00" transparent opacity={0} side={THREE.BackSide}/>
      <meshBasicMaterial attach="material-4" color="#053233" side={THREE.BackSide}/>
      <meshBasicMaterial attach="material-5" color="#053233" side={THREE.BackSide}  />
      </mesh>}
      {isLoggedIn&&<mesh position={[0,(-(modelDetails.Depth/2))-0.5,-1250.5]}>
        <planeGeometry args={[2501,modelDetails.Depth+1]}/>
        <meshBasicMaterial color={"#053233"}/>
      </mesh>}
      {isLoggedIn&&<mesh position={[0,(-(modelDetails.Depth/2))-0.5,1250.5]}>
        <planeGeometry args={[2501,modelDetails.Depth+1]}/>
        <meshBasicMaterial color={"#053233"}side={THREE.BackSide}/>
      </mesh>}
      {isLoggedIn&&<mesh position={[-1250,(-(modelDetails.Depth/2))-0.5,0]} rotation-y={Math.PI/2}>
        <planeGeometry args={[2500,modelDetails.Depth+1]} />
        <meshBasicMaterial color={"#053233"}/>
      </mesh>}
      {isLoggedIn&&<mesh position={[1250,(-(modelDetails.Depth/2))-0.5,0]} rotation-y={-Math.PI/2}>
        <planeGeometry args={[2500,modelDetails.Depth+1]} />
        <meshBasicMaterial color={"#053233"} />
      </mesh>}
      {/* Water Top */}
      <TopWater />
      {/* <Rain/> */}
      {/* <Clouds/> */}
      <group ref={gullRef} position={[0, 15, 0]}>

        <Suspense fallback={null}>
          {gullPositions.map((position, index) => (
            <Gull key={index} pose={0} position={position} animationOffset={index * 2.0}  />
          ))}
        </Suspense>
      </group>
       

      <group ref={fishRef} >

        <Suspense fallback={null}>
        {fishPositions.map((position, index) => (
          <Fish key={index}  pose={0} position={position}  animationOffset={index * 2.0}/>
          ))}
        </Suspense>
      </group>


      <fogExp2
        attach="fog"
        color={"#dddddb"}
        density={
          camera.position.y > 0
            ? 0.5
            : 0
        }
      />
      <fogExp2
        attach="fog"
        color={"#053233"}
        density={
          camera.position.y < 0
            ? 0.001  + Math.abs(camera.position.y * 0.001)
            : 0
        }
      />
    </>
  );
}
