// Collapsible.jsx

import { useState } from 'react';

function Collapsible({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);
  

  return (
    <>
      <button 
        className={`collapsible ${isOpen ? 'active' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
      </button>
      
        <div
          className="content"
          style={{maxHeight: '100%',display:isOpen ? 'block' : 'none'}} 
        >
          {children}
          {/* {console.log(children)} */}
        </div>
      
    </>
  );
}

export default Collapsible;