

import React, { useEffect, useState, useMemo } from "react"
import { useGLTF, useTexture,  useAnimations } from "@react-three/drei"
import { useGraph } from "@react-three/fiber"
import { SkeletonUtils } from "three-stdlib"

export default function Gull({ pose,animationOffset = 0, ...props }) {
  // Fetch model and a separate texture
  const { scene, animations } = useGLTF("./models/low_poly_bird_animated/scene.gltf");
  const texture = useTexture("./textures/bird/blinn2_baseColor.jpeg")
  //LICENSE
  //This work is based on "Low Poly Bird (Animated)" (https://sketchfab.com/3d-models/low-poly-bird-animated-82ada91f0ac64ab595fbc3dc994a3590) by Charlie Tinley (https://sketchfab.com/Tnkii) licensed under CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
  // Skinned meshes cannot be re-used in threejs without cloning them
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene])
  // useGraph creates two flat object collections for nodes and materials
  const { nodes } = useGraph(clone)

  // Extract animation actions
  const { ref, actions, names } = useAnimations(animations)

  // Hover and animation-index states
  const [index, setIndex] = useState(pose)
  // Animate the selection halo



  // Change animation when the index changes
  useEffect(() => {
    // Reset and fade in animation after an index has been changed
    const action = actions[names[index]]
    action.timeScale=5
    action.time = animationOffset 
    action.play()
    // In the clean-up phase, fade it out
   
  }, [index, actions, animationOffset])

  

  return (
    <group ref={ref} rotation-y={Math.PI / 2} {...props} dispose={null}>
      <group

        scale={[0.1, 0.1, 0.1]}>
        <primitive object={nodes._rootJoint} />
        <skinnedMesh
          
          geometry={nodes.Object_7.geometry}
          skeleton={nodes.Object_7.skeleton}
          >
          <meshStandardMaterial map={texture} map-flipY={false} skinning />
        </skinnedMesh>
      </group>
     
    </group>
  )
}
  // Use the useFrame hook to update the bird's position on each frame
  // useFrame((state) => {
  //   const t = state.clock.getElapsedTime();
  //   gullRef.current.position.x += 0.025; // move forward slowly
  //   gullRef.current.position.y = 20 + Math.sin(t)/2; // bob up and down
  // });