// Interface.jsx

import { useContext, useEffect, useRef, useState } from "react";
import Graphs from "./interface/Chart";
import Login from "./interface/Login";
import ControlPanel from "./interface/ControlPanel";
import { DataContext } from "./DataContext";
import { useCameraStore } from "./Store";
import Collapsible from "./interface/Collapsible";

export default function Interface({ isLoggedIn, setIsLoggedIn, children }) {
  const [show, setShow] = useState("");
  const [sidebarActive, setSidebarActive] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [isSignup, setIsSignup] = useState(false);
  const { modelDetails } = useContext(DataContext);
  const sidebarRef = useRef(null);
  useEffect(() => {
    const sidebar = sidebarRef.current;
    if (isLoggedIn) {
      sidebar.style.display = "block";
    } else {
      sidebar.style.display = "none";
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (isLoggedIn) {
      setShow("");
    } else {
      setShow("login");
    }
  }, [isLoggedIn]);

  const handleClick = (id) => {
    if (show === id) {
      setShow("");
      setSidebarActive(false);
    } else {
      setShow(id);
      setSidebarActive(true);
    }
  };
  const divRef = useRef();
  const test = useRef();
  const unsubscribe = useCameraStore.subscribe((pos) => {
    if (isLoggedIn) {
      if (pos.position.y < 0.01) {
        divRef.current.innerText =
          "Depth: " +
          Math.abs(Math.round(pos.position.y * 100) / 100).toFixed(2) +
          "m";
      } else {
        divRef.current.innerText = "";
      }
    }
  });

  useEffect(() => {
    return () => unsubscribe();
  }, []);

  function shoot() {
    alert("NICE");
  }

  return (
    <>
      {isLoggedIn && (
        <div
          id="sidebarLeft"
          className={`hideBar ${sidebarActive ? "active" : ""}`}
        >
          <div className="leftBarDetails">
            <div className="home topButton" id="jumpHome">
              <div className="sideButtonIcon">
                <i className="fa-solid fa-house"></i>
              </div>
            </div>
            <div
              className="home topButton"
              onClick={() => handleClick("login")}
            >
              <div className="sideButtonIcon">
                <i className="fa-solid fa-user"></i>
              </div>
            </div>
            <div
              className="home topButton"
              onClick={() => handleClick("controls")}
            >
              <div className="sideButtonIcon">
                <i className="fa-solid fa-gamepad"></i>
              </div>
            </div>
            <div
              className="home topButton"
              onClick={() => handleClick("graphs")}
            >
              <div className="sideButtonIcon">
                <i className="fa fa-dot-circle-o	"></i>
              </div>
            </div>
          </div>
        </div>
      )}

      {show === "login" && (
        <Login
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          setIsLoggedIn={setIsLoggedIn}
          isLoggedIn={isLoggedIn}
          setShow={setShow}
        />
      )}

      {show === "controls" && (
        <div className="logincontainer">
          <label
            className="close-btn fas fa-times"
            onClick={() => handleClick("controls")}
          ></label>
          <div className="text">
            <p>
              <b>CONTROLS</b>:<br />
              <b>W</b> - Move Forward
              <br />
              <b>A</b> - Move Left
              <br />
              <b>S</b> - Move Backward
              <br />
              <b>D</b> - Move Right
              <br />
              <b>R or SPACEBAR </b> - Move Up <br />
              <b>F or Shift </b> - Move Down <br />
              <b>Arrow Keys</b> - Camera Movement
              <br />
            </p>
          </div>
        </div>
      )}

      {show === "graphs" && (
        <div
          className={`AnalysisContainer ${show === "graphs" ? "active" : ""}`}
        >
          <div id="username">Signed in as: {modelDetails.email}</div>
          <div id="analysisTitle">
            <h1>Analysis Mode</h1>
            <button onClick={() => handleClick("graphs")}>
              Return to Viewer
            </button>
          </div>

          <Graphs />
        </div>
      )}

      <div id="info" className="info">
        <a href="https://www.sealand-projects.com/" target="_blank">
          <img
            src="images/Sealand Projects_White-01.png"
            alt="Sealand Projects"
          />
        </a>
      </div>

      <div id="project" className="project">
        <a href="https://www.sealand-projects.com/" target="_blank">
          <img src="images/SEASITE_White-01.png" />
        </a>
      </div>

      <div id="sidebar" className="hideBar" ref={sidebarRef}>
        <div className="highlightName">{modelDetails.name}</div>
        <div className="highlightDetails">
          <Collapsible title="Details">
            <div className="firstChecker"></div>
          </Collapsible>

          <Collapsible title="Images">
            <div className="highlightImages"></div>
          </Collapsible>
          <Collapsible title="Drawings">
            <div className="highlightDrawings" ref={test}></div>
          </Collapsible>

          <Collapsible title="Certifications">
            <p>Under Construction....</p>
          </Collapsible>
        </div>
      </div>
      {/* <div className="Tester"></div> */}
      {isLoggedIn && <div ref={divRef} id="pos" className="hudLeft" />}
    </>
  );
}

// // Interface.jsx

// import { useState } from "react";
// import Graphs from "./interface/Chart";
// import ControlPanel from "./interface/ControlPanel";

// export default function Interface() {

//   const [show, setShow] = useState('');
//   const [sidebarActive, setSidebarActive] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [user, setUser] = useState(null);
//   const [isSignup, setIsSignup] = useState(false);

//   const handleClick = (id) => {
//     if (show === id) {
//       setShow('');
//       setSidebarActive(false);
//     } else {
//       setShow(id);
//       setSidebarActive(true);
//     }
//   };

//   const handleLogin = (e) => {
//     e.preventDefault();
//     setEmail("");
//     setPassword("");
//   };

//   return (
//     <>

//       {/* Your existing code... */}

//       {show === 'controls' && <ControlPanel handleClick={() => handleClick('controls')} />}
//       {show === 'graphs' && <Graphs />}
//       {/* Your existing code... */}
//     </>
//   );
// }
