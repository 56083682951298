import { ColorType, createChart } from "lightweight-charts";
import { useEffect, useRef, useState } from "react";
import Papa from "papaparse";

export default function AnalyticsCharting() {
  const chartContainerRef = useRef();
  const chartRef = useRef(); // Add a chart ref
  const [dataKey, setDataKey] = useState('aX'); // Default data key
  const [displayText, setDisplayText] = useState('Accelerometer');
  const [mainSeriesVisible, setMainSeriesVisible] = useState(true);
  const [ySeriesVisible, setYSeriesVisible] = useState(true);
  const [zSeriesVisible, setZSeriesVisible] = useState(true);

  const handleButtonClick = (buttonText, dataKey) => {
    setDisplayText(buttonText);
    setDataKey(dataKey);
  };

  useEffect(() => {
    // Fetch the CSV data from a file or API
    fetch("testChart.csv")
      .then((response) => response.text())
      .then((csvText) => {
        Papa.parse(csvText, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: (results) => {
            const csvData = results.data;

            // Convert date strings to yyyy-mm-dd format
            const formattedData = csvData.map((entry) => ({
              time: formatDate(entry.rtcDate),
              value: entry[dataKey], // Use the selected data key
            }));
            const formattedDataY = csvData.map((entry) => ({
              time: formatDate(entry.rtcDate),
              value: entry[dataKey.replace('X', 'Y')], // Use the Y data key
            }));
            const formattedDataZ = csvData.map((entry) => ({
              time: formatDate(entry.rtcDate),
              value: entry[dataKey.replace('X', 'Z')], // Use the Z data key
            }));

            if (chartRef.current) {
              chartRef.current.remove();
            }

            chartRef.current = createChart(chartContainerRef.current, {
              layout: {
                background: { type: ColorType.Solid, color: "white" },
              },
              // leftMargin: 80, // Adjust the left margin to accommodate titles
              // rightMargin: 40, // Adjust the right margin if needed
              width: chartContainerRef.current.clientWidth,
              autoSize: true,
            });

            // Create and populate main data series
            const mainSeries = chartRef.current.addLineSeries({
              color: "#2962FF",
              title: "X Axis", // Add a title for identification
            });
            mainSeries.setData(formattedData);
            mainSeries.applyOptions({
              visible: mainSeriesVisible,
            });

            // Create and populate Y data series
            const ySeries = chartRef.current.addLineSeries({
              color: "#1D707A",
              title: "Y Axis", // Add a title for identification
            });
            ySeries.setData(formattedDataY);
            ySeries.applyOptions({
              visible: ySeriesVisible,
            });

            // Create and populate Z data series
            const zSeries = chartRef.current.addLineSeries({
              color: "#00B3C7",
              title: "Z Axis", // Add a title for identification
            });
            zSeries.setData(formattedDataZ);
            zSeries.applyOptions({
              visible: zSeriesVisible,
            });

            chartRef.current.timeScale().fitContent();
            chartRef.current.timeScale().applyOptions({
              fixLeftEdge: true,
              fixRightEdge: true,
            });
            chartRef.current.applyOptions({
              rightPriceScale: {
                scaleMargins: {
                  top: 0.1,
                  bottom: 0.0,
                },
                invertScale: false,
              },
            });
          },
        });
      });
  }, [dataKey, mainSeriesVisible, ySeriesVisible, zSeriesVisible]);

  // Function to format date as yyyy-mm-dd
  function formatDate(dateStr) {
    const parts = dateStr.split("/");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }

  return (
    <>
      <div className="lightWeightChart" ref={chartContainerRef} style={{ position: "relative" }}>
        <div style={{ position: "absolute", zIndex: 5000, color: "#203C6E" }}>{displayText}</div>
      </div>
      <button onClick={() => handleButtonClick('Accelerometer', 'aX')}>Accelerometer</button>
      <button onClick={() => handleButtonClick('Gyrometer', 'gX')}>Gyrometer</button>
      <button onClick={() => handleButtonClick('Magnometer', 'mX')}>Magnometer</button>

      <div>
        <label>
          X Axis
          <input
            type="checkbox"
            checked={mainSeriesVisible}
            onChange={() => setMainSeriesVisible(!mainSeriesVisible)}
          />
        </label>
        <label>
          Y Axis
          <input
            type="checkbox"
            checked={ySeriesVisible}
            onChange={() => setYSeriesVisible(!ySeriesVisible)}
          />
        </label>
        <label>
          Z Axis
          <input
            type="checkbox"
            checked={zSeriesVisible}
            onChange={() => setZSeriesVisible(!zSeriesVisible)}
          />
        </label>
      </div>
    </>
  );
}
