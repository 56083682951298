import React, { useRef, useMemo } from "react";
import { extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import * as THREE from "three";

import { Water } from "three/examples/jsm/objects/Water.js";

extend({ Water });

function TopWater() {
  const ref = useRef();
  const { gl } = useThree();
  const waterNormals = useLoader(
    THREE.TextureLoader,
    "./textures/water/waternormals.jpg"
  );

  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping;
  const geom = useMemo(() => new THREE.PlaneGeometry(2500, 2500), []);
  const config = useMemo(
    () => ({
      textureWidth: 2500,
      textureHeight: 2500,
      waterNormals,
      
      
      waterColor: 0x053233,
      distortionScale: 3.7,
    }),
    [waterNormals]
  );

  useFrame((state, delta) => {
    // Access the material instance and set its side property to THREE.DoubleSide
    const waterMaterial = ref.current?.material;
    if (waterMaterial) {
      waterMaterial.side = THREE.DoubleSide;
  
    }

    ref.current.material.uniforms.time.value += delta / 4;
  });
  return (
    <>
    <water
      ref={ref}
      args={[geom, config]}
      rotation-x={-Math.PI / 2}
      position={[0, 0, 0]}
    />
    {/* <mesh scale={[window.innerWidth, window.innerHeight, 1]} position={[0, 0, 11.5]}>
  <planeGeometry />
  <meshBasicMaterial  color="#001a33"
    opacity={0.5}
    transparent/>
</mesh> */}
    </>
  );
}

export default TopWater;
