// Login.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
export default function Login({ isLoggedIn, setIsLoggedIn, setShow }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      
      const response = await axios.post('https://seasite.co.uk/api/users/login', { email, password }); //Office
      if(response.data) {
        const userData = {
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        };
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        setEmail('');
        setPassword('');
        setIsLoggedIn(true);
        setShow('');
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        
        background: '#D3DEE5',
        backdrop:"#203d6e4f",
        color:'#203C6E',
        confirmButtonColor: "#203C6E",
        title: 'Error!',
        text: error.response.data.message,
        icon: 'error',
        confirmButtonText: "<p style='color:#D3DEE5'> Okay</p>"
      })
    }
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    setShow('login');
  };

  return (
    <div className="logincontainer">
      {isLoggedIn ? (
        <>
          <button className='logout slide_left' onClick={handleLogout}>Logout</button>
          <div className="text">You are logged in</div>
        </>
      ) : (
        <>
          <div className="text">Login Form</div>
          <form onSubmit={handleLogin}>
            <div className="data">
              <label>Email</label>
              <input type="email" autoComplete="username" placeholder="you@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="data">
              <label>Password</label>
              <input type="password"autoComplete="current-password" placeholder="••••••••"  required value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className="btn">
              <div className="inner"></div>
              <button type="submit">Login</button>
            </div>
           
          </form>
        </>
      )}
    </div>
  );
}
