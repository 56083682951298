
import { useGLTF, useTexture } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { RigidBody } from "@react-three/rapier";
import * as THREE from 'three'
import { DataContext } from "../DataContext";
import { useContext } from "react";
export default function Seabed() { 
  const [colorMap, normalMap, roughnessMap] = useTexture(['./textures/dirt/bed-color.png', './textures/dirt/bed-normal2.png','./textures/dirt/bed-roughness.png' ])
  
  const test = useTexture('./textures/bird/blinn2_baseColor.jpeg')
  colorMap.wrapS= colorMap.wrapT = THREE.RepeatWrapping
  normalMap.wrapS= colorMap.wrapT = THREE.RepeatWrapping
  roughnessMap.wrapS= colorMap.wrapT = THREE.RepeatWrapping
  colorMap.repeat.set(20,20);
  //  normalMap.repeat.set(350,25);
  //  roughnessMap.repeat.set(100,100);
  // colorMap.anisotropy = 16;
  // normalMap.anisotropy = 16;
  const { modelDetails } = useContext(DataContext);
  const seabed = useGLTF('./models/seabed/Sea Floor (4).gltf')
  return (
    <>
      {/* Seabed */}
      {/* <primitive 
        object={seabed.scene}
        position={[0, -45.9 , 0]}
      
      ><meshStandardMaterial map={colorMap} normalMap={normalMap} /></primitive> */}
      <RigidBody type="fixed" >
      <mesh receiveShadow position-y={(-modelDetails.Depth)-5.1}>
        <boxGeometry args={[2500, 10, 2500]} />
        <meshStandardMaterial map={colorMap} normalMap={normalMap} />
      </mesh>
    </RigidBody>
    </>
  );
}
