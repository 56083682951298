import React, { createContext, useState } from 'react';

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [modelDetails, setModelDetails] = useState({});
  const [cameraDetails, setCameraDetails] = useState({});

  return (
    <DataContext.Provider value={{ modelDetails, setModelDetails, cameraDetails, setCameraDetails }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
