
import * as THREE from 'three'
import React, { useEffect, useState, useMemo } from "react"
import { useGLTF, useTexture,  useAnimations } from "@react-three/drei"
import { useGraph } from "@react-three/fiber"
import { SkeletonUtils } from "three-stdlib"

export default function Fish({ pose,animationOffset = 0, ...props }) {
  // Fetch model and a separate texture
  const { scene, animations } = useGLTF("./models/animated_low_poly_fish/scene.gltf");
  const texture = useTexture("./models/animated_low_poly_fish/textures/Material.006_baseColor.jpeg")
//   console.log(scene)
  //LICENSE
  //This work is based on "Animated Low poly Fish" (https://sketchfab.com/3d-models/animated-low-poly-fish-64adc2e5a4be471e8279532b9610c878) by Atlas (https://sketchfab.com/Atlasmodels) licensed under CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)


  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene])
  // useGraph creates two flat object collections for nodes and materials
  const { nodes } = useGraph(clone)

  // Extract animation actions
  const { ref, actions, names } = useAnimations(animations)

  // Hover and animation-index states
  const [index, setIndex] = useState(pose)
  // Animate the selection halo



  // Change animation when the index changes
  useEffect(() => {
    // Reset and fade in animation after an index has been changed
    const action = actions[names[index]]
    action.timeScale=1
    action.time = animationOffset 
    action.play()
    // In the clean-up phase, fade it out
    return () => actions[names[index]].fadeOut(0.5)
  }, [index, actions,animationOffset ])

//   console.log(nodes)

  return (
    <group ref={ref}rotation-z={-Math.PI / 2} rotation-x={-Math.PI / 2}{...props} dispose={null}>
      <group

        scale={[0.5, 0.5, 0.5]}>
        <primitive object={nodes.GLTF_created_0_rootJoint} />
        <skinnedMesh
          
          geometry={nodes.Object_7.geometry}
          skeleton={nodes.Object_7.skeleton}
          >
          <meshStandardMaterial map={texture} map-flipY={false} skinning side={THREE.BackSide} />
        </skinnedMesh>
      </group>
     
    </group>
  )
}
