//Experience.jsx

import { Environment, FlyControls, OrbitControls, PointerLockControls } from '@react-three/drei'
import { EffectComposer, Vignette } from '@react-three/postprocessing'
import Lights from './Lights.jsx'
import World from './World.jsx'
import Buoy from './Buoy.jsx'
import { Perf } from 'r3f-perf'
import { Physics, RigidBody} from '@react-three/rapier'
import { Suspense } from 'react'

export default function Experience({ isLoggedIn })
{
    return <>

        {/* <Perf/> */}
        <Environment 
                    background
                    files="./envMap/kloofendal_38d_partly_cloudy_puresky_2k.hdr"
                />

        {/* <FlyControls rollSpeed={0} c/>  */}
        {/* <OrbitControls  /> */}
        {/* <PointerLockControls/> */}
        {/* <Perf/> */}
        
        <Lights />
                    
        <Physics  gravity={[0, -30, 0]}>

        <Suspense>
            <World isLoggedIn={isLoggedIn}/>
        </Suspense>
        
        <Suspense>
        <RigidBody type="fixed">
        {isLoggedIn && <Buoy  />}
        </RigidBody>
        </Suspense>
        </Physics>
    </>
}