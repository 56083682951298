import { useFrame, useThree } from "@react-three/fiber";
// import { EffectComposer, Vignette } from "@react-three/postprocessing";
import { useContext, useEffect, useState } from "react";
import * as THREE from "three";
import { DataContext } from "./DataContext";
import gsap from 'gsap'
export default function Camera({ isLoggedIn }) {
  const { camera,scene } = useThree();
  const [movementKeys, setMovementKeys] = useState([]);
  const [rotationKeys, setRotationKeys] = useState([]);
  const [gamepad, setGamepad] = useState(null);
  const loginContainer = document.getElementsByClassName("logincontainer")
  const clicker = document.getElementById("root");
  const { modelDetails } = useContext(DataContext);
    const handleKeyDown = (event) => {
      if (!isLoggedIn || $(loginContainer).is(":visible")) return;
      switch (event.key) {
        // Movement keys
        case "w":
        case "W":
          setMovementKeys((prevKeys) => [...prevKeys, "forward"]);
          break;
        case "s":
        case "S":
          setMovementKeys((prevKeys) => [...prevKeys, "backward"]);
          break;
        case "a":
        case "A":
          setMovementKeys((prevKeys) => [...prevKeys, "left"]);
          break;
        case "d":
        case "D":
          setMovementKeys((prevKeys) => [...prevKeys, "right"]);
          break;
        case "r":
        case "R":
        case " ":
          setMovementKeys((prevKeys) => [...prevKeys, "up"]);
          break;
        case "f":
        case "F":
        case "Shift":
          setMovementKeys((prevKeys) => [...prevKeys, "down"]);
          break;
        // Rotation keys
        case "ArrowUp":
          setRotationKeys((prevKeys) => [...prevKeys, "rotateUp"]);
          break;
        case "ArrowDown":
          setRotationKeys((prevKeys) => [...prevKeys, "rotateDown"]);
          break;
        case "ArrowLeft":
          setRotationKeys((prevKeys) => [...prevKeys, "rotateLeft"]);
          break;
        case "ArrowRight":
          setRotationKeys((prevKeys) => [...prevKeys, "rotateRight"]);
          break;
        default:
          break;
      }
    };

    const handleKeyUp = (event) => {
       if (!isLoggedIn || $(loginContainer).is(":visible")) return;
      switch (event.key) {
        // Movement keys
        case "w":
        case "W":
          setMovementKeys((prevKeys) => prevKeys.filter((key) => key !== "forward"));
          break;
        case "s":
        case "S":
          setMovementKeys((prevKeys) => prevKeys.filter((key) => key !== "backward"));
          break;
        case "a":
        case "A":
          setMovementKeys((prevKeys) => prevKeys.filter((key) => key !== "left"));
          break;
        case "d":
        case "D":
          setMovementKeys((prevKeys) => prevKeys.filter((key) => key !== "right"));
          break;
        case "r":
        case "R":
        case " ":
          setMovementKeys((prevKeys) => prevKeys.filter((key) => key !== "up"));
          break;
        case "f":
        case "F":
        case "Shift":
          setMovementKeys((prevKeys) => prevKeys.filter((key) => key !== "down"));
          break;
        // Rotation keys
        case "ArrowUp":
          setRotationKeys((prevKeys) => prevKeys.filter((key) => key !== "rotateUp"));
          break;
        case "ArrowDown":
          setRotationKeys((prevKeys) => prevKeys.filter((key) => key !== "rotateDown"));
          break;
        case "ArrowLeft":
          setRotationKeys((prevKeys) => prevKeys.filter((key) => key !== "rotateLeft"));
          break;
        case "ArrowRight":
          setRotationKeys((prevKeys) => prevKeys.filter((key) => key !== "rotateRight"));
          break;
        default:
          break;
      }
    };
  
  const handleGamepadConnected = (event) => {
    setGamepad(event.gamepad);
  };

  const handleGamepadDisconnected = () => {
    setGamepad(null);
  };

  const handleGamepadInput = (updatedGamepad) => {
    if (!isLoggedIn || $(loginContainer).is(":visible")) return;
    const gamepadAxes = updatedGamepad.axes;
    const gamepadButtons = updatedGamepad.buttons;
  
    const movementThreshold = 0.1; // Adjust this value to control movement sensitivity
    const rotationThreshold = 0.1; // Adjust this value to control rotation sensitivity
  
    // Camera movement
    const moveSpeed = 0.1; // Adjust this value to control movement speed
  
    const movementX = -gamepadAxes[0];
    const movementY = gamepadAxes[1];
  
    if (Math.abs(movementX) > movementThreshold) {
      const moveDirection = new THREE.Vector3(-movementX, 0, 0);
      moveDirection.applyQuaternion(camera.quaternion);
      camera.position.add(moveDirection.multiplyScalar(moveSpeed));
    }
  
    if (Math.abs(movementY) > movementThreshold) {
      const moveDirection = new THREE.Vector3(0, 0, movementY);
      moveDirection.applyQuaternion(camera.quaternion);
      camera.position.add(moveDirection.multiplyScalar(moveSpeed));
    }
  
    // Camera rotation
    const rotateSpeed = 0.02; // Adjust this value to control rotation speed
  
    const rotationX = -gamepadAxes[2];
    const rotationY = -gamepadAxes[3];
  
    if (Math.abs(rotationX) > rotationThreshold) {
      const rotationAngle = rotationX * rotateSpeed;
      const axis = new THREE.Vector3(0, 1, 0);
      const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, rotationAngle);
      camera.quaternion.multiply(quaternion);
    }
  
    if (Math.abs(rotationY) > rotationThreshold) {
      const rotationAngle = rotationY * rotateSpeed;
      const axis = new THREE.Vector3(1, 0, 0);
      const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, rotationAngle);
      camera.quaternion.multiply(quaternion);
    }

    // Vertical movement
    const triggerThreshold = 0.1; // Adjust this value to control trigger sensitivity

    const triggerLeft = gamepadButtons[6].value;
    const triggerRight = gamepadButtons[7].value;

    if (triggerLeft > triggerThreshold) {
      camera.position.y -= moveSpeed;
    }

    if (triggerRight > triggerThreshold) {
      camera.position.y += moveSpeed;
    }
   


    
    
  };
  

  useEffect(() => {
    if(!isLoggedIn){handleLogOut()}
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("gamepadconnected", handleGamepadConnected);
    window.addEventListener("gamepaddisconnected", handleGamepadDisconnected);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.addEventListener("gamepadconnected", handleGamepadConnected);
    window.addEventListener("gamepaddisconnected", handleGamepadDisconnected);
    };
  }, [isLoggedIn]);

  useFrame(() => {
    const step = 0.1; // adjust this value for camera movement speed
    const rotateAngle = Math.PI / 180; // adjust this value for camera rotation speed

    const frontVector = new THREE.Vector3(0, 0, -1); // vector representing the "forward" direction
    

    // Handle gamepad input if a gamepad is connected
    if (gamepad) {
      const updatedGamepad = navigator.getGamepads()[gamepad.index];
      handleGamepadInput(updatedGamepad);
    }

    // Move the camera in the direction it's facing
    if (movementKeys.includes("forward")) {
      const direction = frontVector.clone().applyQuaternion(camera.quaternion);
      camera.position.add(direction.multiplyScalar(step));
    }
    if (movementKeys.includes("backward")) {
      const direction = frontVector.clone().applyQuaternion(camera.quaternion);
      camera.position.add(direction.multiplyScalar(-step));
    }
    if (movementKeys.includes("left")) {
      const direction = new THREE.Vector3(1, 0, 0);
      direction.applyQuaternion(camera.quaternion);
      camera.position.add(direction.multiplyScalar(-step));
    }
    if (movementKeys.includes("right")) {
      const direction = new THREE.Vector3(-1, 0, 0);
      direction.applyQuaternion(camera.quaternion);
      camera.position.add(direction.multiplyScalar(-step));
    }
    if (movementKeys.includes("up")) {
      camera.position.y += step;
    }
    if (movementKeys.includes("down")) {
      camera.position.y -= step;
    }

   // Rotate the camera
   if (rotationKeys.includes("rotateUp")) {
    const axis = new THREE.Vector3(1, 0, 0);
    const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, rotateAngle);
    camera.quaternion.multiply(quaternion);
  }
  if (rotationKeys.includes("rotateDown")) {
    const axis = new THREE.Vector3(1, 0, 0);
    const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, -rotateAngle);
    camera.quaternion.multiply(quaternion);
  }
  if (rotationKeys.includes("rotateLeft")) {
    const axis = new THREE.Vector3(0, 1, 0);
    const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, rotateAngle);
    camera.quaternion.multiply(quaternion);
  }
  if (rotationKeys.includes("rotateRight")) {
    const axis = new THREE.Vector3(0, 1, 0);
    const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, -rotateAngle);
    camera.quaternion.multiply(quaternion);
  }
  if (camera.position.y < 0) {
    scene.fog = new THREE.FogExp2("#053233", 0.0025 + Math.abs(camera.position.y * 0.0025));
  } else {
    scene.fog = null;
  }
});

  useEffect(() => {
    // Set camera settings
    camera.fov = 60; // Adjust this value to match the default FOV
    camera.near = 1;
    camera.far = 5000;
    camera.position.set(0, 50, 50);

    camera.updateProjectionMatrix();
  }, []);

  const handleJumpHomeClick = () => {
    camera.position.set(0, modelDetails.Depth/4, modelDetails.Depth-3);
    camera.rotation.set(0, 0, 0);
  };

  useFrame(() => {
    // console.log(camera.position.x)
    const jumpHome = document.querySelector("#jumpHome");
    if (jumpHome) {
      jumpHome.addEventListener("click", handleJumpHomeClick);
    }
    return () => {
      if (jumpHome) {
        jumpHome.removeEventListener("click", handleJumpHomeClick);
      }
    };
  }, []);

  const tl = gsap.timeline()
  useEffect(() => {
    if(isLoggedIn){  tl.to(camera.position,{
        x:0,
        y:modelDetails.Depth/4,
        z: modelDetails.Depth-3,
        duration:1
      })}
    
  }, [modelDetails]);
  const handleLogOut = () =>{
    camera.position.set(0, 50, 500);
    camera.rotation.set(0, 0, 0);
  }
  return (
   null
  );
}
