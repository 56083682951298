//App.jsx
import { Canvas } from "@react-three/fiber";
import Camera from "./Camera";
import Experience from "./Experience";
import Interface from "./Interface";
import { useEffect, useState } from "react";
import axios from "axios";
import { DataProvider } from "./DataContext";

export default function App() {
  let modelDetail;
  let name = "";
  let depth = 0;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : null;
  
    if (user && user.refreshToken) {
      axios.post('https://seasite.co.uk/api/users/refresh-token', {}, { 
        headers: {
          Authorization: `Bearer ${user.refreshToken}`
        }
      }).then(res => {          
        if (res.status === 200) {
          setIsLoggedIn(true);
        }
      }).catch(error => {
        if (error.response.status === 403) {  
          setIsLoggedIn(false);
        }
      });
    }
  }, []);
  return (
    <DataProvider>
    <>
      <Canvas  shadows>
        <Camera isLoggedIn={isLoggedIn} />
        <Experience isLoggedIn={isLoggedIn} />
      </Canvas>
      <Interface isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
    </></DataProvider>
  );
}
